import React, { useState, useEffect, memo, Fragment } from "react";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";

//apexcharts
import Chart from "react-apexcharts";


// Redux Selector / Action
import { useSelector } from "react-redux";
import Circularprogressbar from "../../../../components/circularprogressbar";
// Import selectors & action from setting store

import * as SettingSelector from "../../../../store/setting/selectors";
//Count-up
import CountUp from "react-countup";


// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";
import CountCard from "./CountCard";

const Index = memo(() => {
  const { api, UserData, authToken, userRole } = useMyContext();

  const navigate = useNavigate()
  useEffect(() => {
    if (userRole === 'User') {
      navigate('/dashboard/bookings');
    }
  }, [userRole]);

  useSelector(SettingSelector.theme_color);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };

  const variableColors = getVariableColor();
  const colors = [variableColors.primary, variableColors.info];

  useEffect(() => {
    return () => colors;
  });


  const upArrow = (
    <svg className="" width="24" height="24px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
      />
    </svg>
  );
  const downArrow = (
    <svg className="" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
      />
    </svg>
  );

  const [counts, setCounts] = useState([{
    offline: '',
    online: '',
    users: '',
    agents: '',
    pos: '',
    organizer: '',
    Scanner: ''
  }]);
  const [sale, setSale] = useState([{
    offline: '',
    online: '',
    agents: '',
    pos: '',
    agentsToday: '',
    posToday: '',
    organizer: '',
    Scanner: ''
  }]);
  const [cFees, setCFess] = useState([{
    offline: '',
    online: '',
    agents: '',
    pos: '',
    organizer: '',
    Scanner: ''
  }]);
  const [weeklySales, setWeeklySales] = useState([]);
  const [weeklyConvenienceFee, setWeeklyConvenienceFee] = useState([]);

  const getCounts = async () => {
    await axios.get(`${api}bookingCount/${UserData?.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then((res) => {
      if (res.data.status) {
        setCounts({
          offline: res.data.offlineBookings,
          online: res.data.onlineBookings,
          users: res.data.userCount,
          agents: res.data.agentCount,
          pos: res.data.posCount,
          organizer: res.data.organizerCount,
          scanner: res.data.scannerCount
        });
      }
    }).catch((err) => console.log(err));
  };
  const getSaleCounts = async () => {
    await axios.get(`${api}calculateSale/${UserData?.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then((res) => {
      if (res.data.status) {
        let convenienceFee = res.data?.convenienceFee
        let weeklySales = res.data?.salesDataNew
        setWeeklySales(weeklySales)
        setWeeklyConvenienceFee(convenienceFee)
        setSale({
          offline: res.data.offlineAmount,
          agents: res.data.agentBooking,
          agentsToday: res.data.agentToday,
          // agant
          cash: { today: res.data.cashSales?.today, total: res.data.cashSales?.total },
          upi: { today: res.data.upiSales?.today, total: res.data.upiSales?.total },
          nb: { today: res.data.netBankingSales?.today, total: res.data.netBankingSales?.total },

          //
          online: res.data.onlineAmount,
          pos: res.data.posAmount,
          posToday: res.data.posTodayAmount,
        });
        setCFess({
          offline: res.data.offlineCNC,
          agents: res.data.agentCNC,
          online: res.data.onlineCNC,
          pos: res.data.posCNC,
        });
      }
    }).catch((err) => console.log(err));
  };

  useEffect(() => {
    getCounts()
    getSaleCounts()
  }, []);

  const growth = [
    {
      color: variableColors.info,
      value: 40,
      id: "circle-progress-06",
      svg: upArrow,
      name: "Online Bookings",
      start: 0,
      end: counts?.online,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 40,
      id: "circle-progress-06",
      svg: upArrow,
      name: "Offline Bookings",
      start: 0,
      end: counts?.offline,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 91,
      id: "circle-progress-01",
      svg: upArrow,
      name: "Total Users",
      start: 0,
      end: counts?.users,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 80,
      id: "circle-progress-02",
      svg: downArrow,
      name: "Agents",
      start: 0,
      end: counts?.agents,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 70,
      id: "circle-progress-03",
      svg: downArrow,
      name: "POS Users",
      start: 0,
      end: counts?.pos,
      duration: 3,
    },
    {
      color: variableColors.info,
      value: 60,
      id: "circle-progress-04",
      svg: upArrow,
      name: "Scanners",
      start: 0,
      end: counts?.scanner,
      duration: 3,
    },
    {
      color: variableColors.primary,
      value: 50,
      id: "circle-progress-05",
      svg: upArrow,
      name: "Organizer",
      start: 0,
      end: counts?.organizer,
      duration: 3,
    },
  ];
  const cardData = [
    { title: "Online", amount: sale?.online, note: "Available to pay out." },
    { title: "EaseBuzz", amount: sale?.online, note: "Available to pay out." },
    { title: "Instamojo", amount: 0, note: "Available to pay out." },
    { title: "PhonePe", amount: 0, note: "Available to pay out." },
    { title: "Offline", amount: sale?.offline, note: "Transactions this month" },
    { title: "Agent Sales", amount: sale?.agents, note: "Transactions this month" },
    { title: "POS Sales", amount: sale?.pos, note: "Transactions this month" },
    { title: "Organizer Sales", amount: 0, note: "Transactions this month" },
  ];
  const feeCards = [
    { title: "Online C Fees", amount: cFees?.online, note: "Available to pay out." },
    { title: "EaseBuzz C Fees", amount: cFees?.online, note: "Available to pay out." },
    { title: "Instamojo C Fees", amount: 0, note: "Available to pay out." },
    { title: "PhonePe C Fees", amount: 0, note: "Available to pay out." },
    { title: "Offline C Fees", amount: cFees?.offline, note: "Transactions this month" },
    { title: "Agent Sales C Fees", amount: cFees?.agents, note: "Transactions this month" },
    { title: "POS Sales C Fees", amount: cFees?.pos, note: "Transactions this month" },
    { title: "Organizer Sales C Fees", amount: 0, note: "Transactions this month" },
  ];

  const getLast7DaysWeekdays = () => {
    const categories = ["S", "M", "T", "W", "T", "F", "S"];
    const result = [];
    const today = new Date();
    for (let i = 6; i >= 0; i--) {
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - i);
      const dayIndex = pastDate.getDay();
      result.push(categories[dayIndex]);
    }

    return result;
  };
  const commonChartOptions = {
    chart: {
      stacked: true,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "28%",
        endingShape: "rounded-4",
        borderRadius: 3,
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 3,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 7,
    },
    xaxis: {
      categories: getLast7DaysWeekdays(),
      labels: {
        minHeight: 20,
        maxHeight: 20,
        style: { colors: "#8A92A6" },
      },
    },
    yaxis: {
      title: { text: "" },
      labels: {
        minWidth: 20,
        maxWidth: 100,
        style: { colors: "#8A92A6" },
      },
    },
    fill: { opacity: 1 },
    tooltip: {
      y: {
        formatter: (val) => `₹${val}`,
      },
    },
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: { height: 130 },
        },
      },
    ],
  };

  const saleChart = {
    options: {
      ...commonChartOptions,
      colors: colors,
    },
    series: [
      {
        name: "Online Sale",
        data: weeklySales && weeklySales[0]?.data,
      },
      {
        name: "Offline Sale",
        data: weeklySales && weeklySales[1]?.data,
      },
    ],
  };

  const convenienceChart = {
    options: {
      ...commonChartOptions,
      colors: colors,
    },
    series: [
      {
        name: "Online Sale",
        data: weeklyConvenienceFee[0]?.data,
      },
      {
        name: "Offline Sale",
        data: weeklyConvenienceFee[1]?.data,
      },
    ],
  };
  const GetSum = (numbers) => {
    let sum = numbers?.reduce((acc, curr) => acc + curr, 0);
    return sum;
  }
  return (
    <Fragment>
     
      <Row>
        {(userRole === 'Admin' || userRole === 'Organizer') &&
          <>
            <Col lg={12} md={12}>
              <div className="overflow-hidden d-slider1">
                <Swiper
                  as="ul"
                  className="p-0 m-0 swiper-wrapper list-inline"
                  slidesPerView={3.5}
                  spaceBetween={32}
                  autoplay={true}
                  modules={[Navigation]}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    1500: { slidesPerView: 6 },
                    1920: { slidesPerView: 6 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 },
                  }}
                  data-aos="fade-up"
                  data-aos-delay="700"
                >
                  {growth.map((item, index) => {
                    return (
                      <SwiperSlide className="card card-slide" key={index}>
                        <Card.Body>
                          <div className="progress-widget mb-2">
                            <Circularprogressbar
                              stroke={item.color}
                              width="60px"
                              height="60px"
                              Linecap="rounded"
                              trailstroke="#ddd"
                              strokewidth="4px"
                              style={{ width: 60, height: 60 }}
                              value={item.value}
                              id={item.id}
                            >
                              {item.svg}
                            </Circularprogressbar>
                            <div className="progress-detail">
                              <p className="mb-2">{item.name}</p>
                              <h4 className="counter">

                                <CountUp
                                  start={item.start}
                                  end={item.end}
                                  duration={item.duration}
                                  separator=""
                                  decimals={item.decimal}
                                />
                                {/* {item.suffix ? item.suffix : "K"} */}
                              </h4>
                            </div>
                          </div>
                        </Card.Body>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="swiper-button swiper-button-next"></div>
                <div className="swiper-button swiper-button-prev"></div>
              </div>
            </Col>
            {/* //firsr row */}
            <Col lg="3" md="6">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="d-flex align-items-start justify-content-between mb-2">
                    <p className="mb-0 text-dark">Weekly Sales</p>
                    <Link
                      className="badge rounded-pill bg-primary-subtle"
                      to="#javascript:void(0);"
                    >
                      View
                    </Link>
                  </div>
                  <div className="mb-3">
                    <h2 className="counter">
                      ₹
                      <CountUp
                        start={0}
                        end={GetSum(weeklySales[0]?.data) + GetSum(weeklySales[1]?.data)}
                        duration={3}
                        useEasing={true}
                        separator=","
                      />
                    </h2>
                    <small>Last updated 1 hour ago.</small>
                  </div>
                  <div>
                    <Chart
                      options={saleChart?.options}
                      series={saleChart?.series}
                      type="bar"
                      height="100%"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="9" md="6">
              <Row>
                {cardData.map((data, index) => (
                  <Col lg="3" md="6" key={index}>
                    <CountCard
                      
                      title={data?.title}
                      amount={data?.amount}
                      note={data?.note}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            {/* //second row */}
            <Col lg="3" md="6">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="d-flex align-items-start justify-content-between mb-2">
                    <p className="mb-0 text-dark">Weekly Convenience Fee</p>
                    <Link
                      className="badge rounded-pill bg-primary-subtle"
                      to="#javascript:void(0);"
                    >
                      View
                    </Link>
                  </div>
                  <div className="mb-3">
                    <h2 className="counter">
                      ₹
                      <CountUp
                        start={0}
                        end={GetSum(weeklyConvenienceFee[0]?.data) + GetSum(weeklyConvenienceFee[1]?.data)}
                        duration={3}
                        useEasing={true}
                        separator=","
                      />
                    </h2>
                    <small>Last updated 1 hour ago.</small>
                  </div>
                  <div>
                    <Chart
                      options={convenienceChart?.options}
                      series={convenienceChart?.series}
                      type="bar"
                      height="100%"
                    ></Chart>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="9" md="6">
              <Row>
                {feeCards.map((card, index) => (
                   <Col lg="3" md="6" key={index}>
                     <CountCard
                       title={card?.title}
                       amount={card?.amount}
                       note={card?.note}
                     />
                   </Col>
                ))}
              </Row>
            </Col>
          </>
        }
        {
          userRole === 'POS' &&
          <>
            <Col lg="8" md="6">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="d-flex align-items-start justify-content-between mb-2">
                    <p className="mb-0 text-dark">Total Sales</p>
                    <Link
                      className="badge rounded-pill bg-primary-subtle"
                      to="#javascript:void(0);"
                    >
                      View
                    </Link>
                  </div>
                  <div className="mb-3">
                    <h2 className="counter">
                      ₹
                      <CountUp
                        start={0}
                        end={sale?.offline + sale?.online}
                        duration={3}
                        useEasing={true}
                        separator=","
                      />
                    </h2>
                    <small>Last updated 1 hour ago.</small>
                  </div>
                  <div>
                    <Chart
                      options={saleChart?.options}
                      series={saleChart?.series}
                      type="bar"
                      height="100%"
                    ></Chart>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Row>
                <Col lg="12" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Total Sales</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.pos}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col>
                <Col lg="12" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Today Total</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.posToday}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </>
        }
        {
          userRole === 'Agent' &&
          <>
            <Col lg="8" md="6">
              <Card className="card-block card-stretch card-height">
                <Card.Body>
                  <div className="d-flex align-items-start justify-content-between mb-2">
                    <p className="mb-0 text-dark">Total Sales</p>
                    <Link
                      className="badge rounded-pill bg-primary-subtle"
                      to="#javascript:void(0);"
                    >
                      View
                    </Link>
                  </div>
                  <div className="mb-3">
                    <h2 className="counter">
                      ₹
                      <CountUp
                        start={0}
                        end={sale?.agents}
                        duration={3}
                        useEasing={true}
                        separator=","
                      />
                    </h2>
                    <small>Last updated 1 hour ago.</small>
                  </div>
                  <div>
                    <Chart
                      options={saleChart?.options}
                      series={saleChart?.series}
                      type="bar"
                      height="100%"
                    ></Chart>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Row>
                <Col lg="12" md="6">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Total Sales</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={sale?.agents}
                          duration={3}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="12" md="6">
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">Today Total</span>
                        <Link
                          className="badge rounded-pill bg-primary-subtle"
                          to="#javascript:void(0);"
                        >
                          Total
                        </Link>
                      </div>
                      <h2 className="counter">
                        ₹
                        <CountUp
                          start={0}
                          end={sale?.agentsToday}
                          duration={3}
                          useEasing={true}
                        />
                      </h2>
                      <small>Transactions this month</small>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col lg="3" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Agent Sales</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.agents}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col> */}

              </Row>
            </Col>
            <Col lg="12" md="6">
              <Row>
                <Col lg="4" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark ">Total Cash</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.cash?.total}
                              duration={3}
                              useEasing={true}
                              separator=""
                            />
                          </h2>
                          <small>Available to pay out.</small>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Today Cash</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.cash?.today}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col>
                <Col lg="4" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark ">Total UPI</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.upi?.total}
                              duration={3}
                              useEasing={true}
                              separator=""
                            />
                          </h2>
                          <small>Available to pay out.</small>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Today UPI</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.upi?.today}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col>
                <Col lg="4" md="6">
                  <Row>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark ">Total Net Banking</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.nb?.total}
                              duration={3}
                              useEasing={true}
                              separator=""
                            />
                          </h2>
                          <small>Available to pay out.</small>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-12">
                      <Card className="card-block card-stretch card-height">
                        <Card.Body>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-dark">Today Net Banking</span>
                            <Link
                              className="badge rounded-pill bg-primary-subtle"
                              to="#javascript:void(0);"
                            >
                              Total
                            </Link>
                          </div>
                          <h2 className="counter">
                            ₹
                            <CountUp
                              start={0}
                              end={sale?.nb?.today}
                              duration={3}
                              useEasing={true}
                            />
                          </h2>
                          <small>Transactions this month</small>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </>
        }
      </Row>
    </Fragment>
  );
});

Index.displayName = "Index";
export default Index;
