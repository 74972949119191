import React, { useEffect, useState } from 'react'
import CountCard from '../../Dashboard/CountCard'
import { Col } from 'react-bootstrap'

const BookingCount = ({ data }) => {
    const [counts, setCounts] = useState({
        totalDiscount: 0,
        totalAmount: 0,
        totalQuantity: 0,
    });
    useEffect(() => {
        if (data) {
            calculateTotals(data)
        }
    }, [data]);
    const calculateTotals = (bookings) => {
        let discountSum = 0;
        let amountSum = 0;
        let quantitySum = 0;
        let ticketSum = 0;
        bookings?.forEach(booking => {
            const discount = Number(booking?.discount || (booking.bookings && booking.bookings[0]?.discount) || 0);
            const total = Number((booking.bookings && booking.bookings[0]?.amount) || booking?.amount || 0);
            const tickets = 1;
            const quantity = Number(booking?.quantity);
            discountSum += discount;
            amountSum += total;
            quantitySum += tickets;
            ticketSum += quantity;
        });
        setCounts({
            totalDiscount: discountSum,
            totalAmount: amountSum,
            totalBookings: quantitySum,
            totalTicket: ticketSum,
        });
    };
    const countCardData = [
        { title: 'Total Amount', amount: counts?.totalAmount },
        { title: 'Total Discount', amount: counts?.totalDiscount },
        { title: 'Total Bookings', amount: counts?.totalBookings, hideCurrency: true },
        { title: 'Total Tickets', amount: counts?.totalTicket, hideCurrency: true },
    ];
    return (
        <>
            {countCardData.map((data, index) => (
                <Col key={index} sm="2">
                    <CountCard
                        title={data.title}
                        amount={data.amount}
                        hideCurrency={data.hideCurrency}
                    />
                </Col>
            ))}
        </>
    )
}

export default BookingCount
