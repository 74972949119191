import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useDataTable from '../../../../components/hooks/useDatatable';
import $ from "jquery";
import { useMyContext } from '../../../../Context/MyContextProvider';
import axios from 'axios';
import CommonDateRange from '../CustomHooks/CommonDateRange';

const AgentReports = () => {
    const { api, authToken } = useMyContext();
    const [report, setReport] = useState();
    const [dateRange, setDateRange] = useState('');

    const listtableRef = useRef(null);

    const GetBookings = async () => {
        const queryParams = [];
        if (dateRange) queryParams.push(`date=${dateRange}`);
        const url = `${api}agent-report${queryParams.length ? `?${queryParams?.join('&')}` : ''}`;
        await axios
            .get(url, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                setReport(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);
    useEffect(() => {
        GetBookings();
    }, [dateRange]);

    const columns = useRef([
        {
            data: null,
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1
        },
        {
            data: 'agent_name',
            title: "Name",
        },
        {
            data: 'booking_count',
            title: "Total Bookings", 
        },
        {
            data: 'today_booking_count',
            title: "Today Bookings",
        },
        {
            data: 'today_total_amount',
            title: "Today Collection",
            render: (data) => '₹' + data
        },
        {
            data: 'total_UPI_bookings',
            title: "UPI Bookings",
        },
        {
            data: 'total_Cash_bookings',
            title: "Cash Bookings",
        },
        {
            data: 'total_Net_Banking_bookings',
            title: "Net Banking Bookings",
        },
        {
            data: 'total_UPI_amount',
            title: "UPI Amount",
            render: (data) => '₹' + data
        },
        {
            data: 'total_Cash_amount',
            title: "Cash Amount",
            render: (data) => '₹' + data
        },
        {
            data: 'total_Net_Banking_amount',
            title: "Net Banking Amount",
            render: (data) => '₹' + data
        },
        {
            data: 'total_discount',
            title: "Total Discount",
            render: (data) => '₹' + data
        },
        {
            data: 'total_amount',
            title: "Total Amount",
            render: (data) => '₹' + data
        },
    ]);

    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: report,
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });
    return (
        <Row>
            <Col lg="12" md="12">
                <Card className="card-block">
                    <Card.Header>
                        <h4 className="">
                            Agents Report
                        </h4>
                    </Card.Header>
                    <CommonDateRange setState={setDateRange} />
                    <Card.Body className='px-0'>
                        <div className="table-responsive">
                            <table
                                id="datatable-ecom"
                                ref={listtableRef}
                                className="data-tables table custom-table movie_table"
                            ></table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default AgentReports
