import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric-pure-browser';
import { QRCodeCanvas } from 'qrcode.react';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useMyContext } from '../../../../../Context/MyContextProvider';
const TicketCanvas = ({ ticketName, ticketBG, eventDate, eventTime, eventVenue, OrderId }) => {
  const { api } = useMyContext()
  const canvasRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const fetchImage = async () => {
    try {
      const response = await axios.post(
        `${api}get-image/retrive`,
        { path: ticketBG },
        { responseType: 'blob' }
      );
      const imageUrl = URL.createObjectURL(response.data);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error('Image fetch error:', error);
    }
  };

  useEffect(() => {
    if (ticketBG) {
      fetchImage();
    }
  }, [ticketBG]);

  useEffect(() => {
    if (ticketBG) {
      fetchImage();
    }
  }, [ticketBG]);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);
    const showLoadingIndicator = () => {
      const loaderText = new fabric.Text('Generating Ticket...', {
        left: canvas.width / 2,
        top: canvas.height / 2,
        fontSize: 20,
        fill: '#555',
        fontFamily: 'Comic Sans MS',
        fontWeight: 'bold',
        fontStyle: 'italic',
        underline: true,
        shadow: new fabric.Shadow({
          color: 'rgba(0,0,0,0.3)',
          blur: 5,
          offsetX: 2,
          offsetY: 2,
        }),
        originX: 'center',
        originY: 'center',
        selectable: false, // Disable dragging
        evented: false, // Disable event interactions
      });

      canvas.add(loaderText);
      canvas.renderAll();
      return loaderText;
    };

    const loadBackgroundImage = (url) => {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, (img) => {
          if (img) {
            resolve(img);
          } else {
            reject(new Error('Failed to load image'));
          }
        }, { crossOrigin: 'anonymous' });
      });
    };

    const drawCanvas = async () => {
      const loader = showLoadingIndicator();
      if (imageUrl) {
        try {
          canvas.remove(loader);
          // Load and set the background image
          const img = await loadBackgroundImage(imageUrl);
          const imgWidth = img.width;
          const imgHeight = img.height;

          canvas.setDimensions({ width: imgWidth, height: imgHeight });
          img.scaleToWidth(imgWidth);
          img.scaleToHeight(imgHeight);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            crossOrigin: 'anonymous',
          });

          // Add QR code
          const qrCodeCanvas = qrCodeRef.current;
          if (qrCodeCanvas) {
            const qrCodeDataURL = qrCodeCanvas.toDataURL('image/png');

            fabric.Image.fromURL(qrCodeDataURL, (qrImg) => {
              const qrCodeWidth = 100;
              const qrCodeHeight = 100;
              const padding = 5;
              const qrPositionX = 80;
              const qrPositionY = 80;

              // Create a white rectangle for padding
              const qrBackground = new fabric.Rect({
                left: qrPositionX - padding,
                top: qrPositionY - padding,
                width: qrCodeWidth + padding * 2,
                height: qrCodeHeight + padding * 2,
                fill: 'white',
                selectable: false,
                evented: false,
              });

              qrImg.set({
                left: qrPositionX,
                top: qrPositionY,
                selectable: false,
                evented: false,
                scaleX: qrCodeWidth / qrImg.width,
                scaleY: qrCodeHeight / qrImg.height,
              });

              // Add the rectangle and the QR code image to the canvas
              canvas.add(qrBackground, qrImg);
              canvas.renderAll();
            });
          }
        } catch (error) {
          console.error('Error drawing canvas:', error);
        }
      }
    };

    drawCanvas();

    return () => {
      canvas.dispose(); // Cleanup on unmount
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl, OrderId]);

  // Download functionality
  const downloadCanvas = () => {
    setLoading(true);
    const canvas = canvasRef.current;
    try {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `ticket_${OrderId || 'event'}.png`;
      link.click();
    } catch (error) {
      console.error('Download error:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button
          variant="primary"
          style={{ width: '50%' }}
          className="d-flex align-align-items-center justify-content-center gap-3 mb-2"
          onClick={() => downloadCanvas()}
          disabled={loading}
        >
          <span className="p-0 m-0">
            {loading ? 'Please Wait...' : 'Download'}
          </span>
          <div>
            <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24" height="32"><path opacity="0.4" d="M2 7.916V16.084C2 19.623 4.276 22 7.665 22H16.335C19.724 22 22 19.623 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916Z" fill="currentColor"></path><path d="M7.72033 12.8555L11.4683 16.6205C11.7503 16.9035 12.2493 16.9035 12.5323 16.6205L16.2803 12.8555C16.5723 12.5615 16.5713 12.0865 16.2773 11.7945C15.9833 11.5025 15.5093 11.5025 15.2163 11.7965L12.7493 14.2735V7.91846C12.7493 7.50346 12.4133 7.16846 11.9993 7.16846C11.5853 7.16846 11.2493 7.50346 11.2493 7.91846V14.2735L8.78333 11.7965C8.63633 11.6495 8.44433 11.5765 8.25133 11.5765C8.06033 11.5765 7.86833 11.6495 7.72233 11.7945C7.42933 12.0865 7.42833 12.5615 7.72033 12.8555Z" fill="currentColor"></path></svg>
          </div>

        </Button>
      </div>
      {
        loadingImage ?
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          :
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <canvas ref={canvasRef} />
          </div>
      }
      <div style={{ display: 'none' }}>
        <QRCodeCanvas ref={qrCodeRef} value={OrderId} size={150} />
      </div>
    </>
  );
};

export default TicketCanvas;
