import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import BookingsAttendee from '../LandingEvents/OrderComps/BookingsAttendee'
import axios from 'axios'
import { useMyContext } from '../../../../../Context/MyContextProvider'

// write code for react bootstrap modal 
const AttendeeModal = ({ handleCloseModal, data, show,category }) => {
    const {api} = useMyContext()
    const [attendeeKeys, setAttendeeKeys] = useState([])

    useEffect(() => {
        if(data){
            fetchCategoryData()
        }
        // console.log(data)
    }, [data]);

    const fetchCategoryData = async () => {
        try {
            const response = await axios.get(`${api}category-title/${category}`);
            setAttendeeKeys(response.data?.customFieldsData);
        } catch (err) {
            console.log(err.message);
        } finally {
            // setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={() => handleCloseModal()} size='xl'>
            <Modal.Header closeButton>
                <h4>Attendees</h4>
                </Modal.Header>
            <Modal.Body>
                <BookingsAttendee attendeeList={data} apiData={attendeeKeys} />
            </Modal.Body>
        </Modal>

    )
}

export default AttendeeModal
