import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import loader from '../../../../assets/event/stock/loder.gif';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useMyContext } from "../../../../Context/MyContextProvider";
import useDataTable from "../../../../components/hooks/useDatatable";
import QRGenerator from "../Events/Tickets/QRGenerator";
import generateQRCodeZip from "../Events/Tickets/generateQRCodeZip";
import BatchDataModel from "./BatchDataModel";
const CbList = memo(() => {
    const { api, UserData, formatDateTime, authToken } = useMyContext();
    const [bookings, setBookings] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [show, setShow] = useState(false)
    const GetBookings = async () => {
        await axios
            .get(`${api}complimentary-bookings/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);

    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "name", title: "Name" },
        { data: "number", title: "Number" },
        { data: "event_name", title: "Event Name" },
        { data: "ticket_name", title: "Ticket Type" },
        { data: "booking_count", title: "Totol Bookings" },
        {
            data: null,
            orderable: false,
            searchable: false,

            title: "Disable",
            render: function (data) {
                const Checked = data?.is_deleted === true && "checked";
                return `<div class="flex align-items-center list-user-action">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-table="action" data-id=${data?.id} data-method="disable" ${Checked}>
                            </div>
                        </div>`;
            },
        },
        {
            data: "booking_date",
            title: "Generate Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                const isShow = data?.type === 1
                return `<div class="flex align-items-center list-user-action">
                        ${isShow ?
                        `<button class="btn btn-sm btn-icon btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="resend" data-id=${data?.batch_id} data-method="edit" data-table="action">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                      <path d="M15.8325 8.17463L10.109 13.9592L3.59944 9.88767C2.66675 9.30414 2.86077 7.88744 3.91572 7.57893L19.3712 3.05277C20.3373 2.76963 21.2326 3.67283 20.9456 4.642L16.3731 20.0868C16.0598 21.1432 14.6512 21.332 14.0732 20.3953L10.106 13.9602" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                            </button>`
                        : ''
                    }            
                            <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Zip" data-id=${data?.batch_id} data-method="Zip" data-table="action">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" height="20"><path d="M13.0801 1H6C4.89543 1 4 1.89543 4 3V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V8.73981M13.0801 1C13.664 1 14.2187 1.25513 14.5986 1.69841L19.5185 7.43822C19.8292 7.80071 20 8.26239 20 8.73981M13.0801 1V5.73981C13.0801 7.39666 14.4232 8.73981 16.0801 8.73981H20" stroke="currentColor"></path><path d="M9.15961 13.1986L9.15957 13.1986L9.15961 13.1986Z" stroke="currentColor" stroke-linecap="round"></path><line x1="12.975" y1="12.6181" x2="11.2497" y2="18.6566" stroke="currentColor" stroke-linecap="round"></line><path d="M15.1037 17.8012C15.1037 17.8012 15.1037 17.8013 15.1036 17.8014L15.1037 17.8013L15.1037 17.8012Z" stroke="currentColor" stroke-linecap="round"></path></svg>
                            </button>
                        </div>`;
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback: (data) => {
            switch (data.method) {
                case "Zip":
                    HandleModel(data?.id);
                    break;
                case "edit":
                    HandleResend(data?.id);
                    break;
                default:
                    break;
            }
        },
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });


    const HandleResend = async (id) => {
        setShow(true);
        const data = await fetchBookings(id)
        setBatchData(data?.bk);
    }
    const DeleteBooking = async (id) => {
        let data = bookings?.find((item) => item?.id === id);
        if (data?.is_deleted === true) {
            await axios
                .get(`${api}restore-booking/${data?.token || data?.order_id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Enabled!",
                            text: "Ticket enabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        } else {
            await axios
                .delete(`${api}delete-booking/${data?.token || data?.order_id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Disabled!",
                            text: "Ticket disabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    };


    const [loading, setLoading] = useState(false);
    const HandleModel = (data) => {
        AskAlert('Do u want to make a zip again', 'Yes Make A Zip', data)
    }

    const AskAlert = (title, buttonText, data) => {
        Swal.fire({
            title: "Are you sure?",
            text: title,
            icon: "warning",
            showCancelButton: true,
            backdrop: `rgba(60,60,60,0.8)`,
            confirmButtonText: buttonText,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const bks = await fetchBookings(data)
                generateZip(bks?.qrCodeIds)
            }
        });
    }
    const fetchBookings = async (data) => {
        try {
            setLoading(true)
            const res = await axios.post(
                `${api}fetch-batch-cb/${UserData?.id}`,
                { batch_id: data },
                {
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                    },
                }
            );

            if (res.data.status) {
                let bk = res.data.tokens;
                let qrCodeIds = bk?.map((item) => ({
                    token: item?.token,
                    name: item?.name,
                    email: item?.email,
                    number: item?.number,
                }));
                return { bk, qrCodeIds };
            } else {
                console.error("Response status is false");
                return null;
            }
        } catch (error) {
            console.error("Error fetching bookings:", error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const generateZip = async (data) => {
        console.log(data);
        await generateQRCodeZip({
            bookings: data,
            QRGenerator: QRGenerator,
            loader: loader
        });
    };

    const onHide = () => {
        setShow(false);
        setBatchData([]);
    }

    return (
        <Fragment>
            {!loading &&
                <BatchDataModel show={show} onHide={onHide} batchData={batchData} />
            }
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Complimentary Bookings</h4>
                                <div
                                    className="progress-bar bg-primary"
                                    data-toggle="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="70"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                        width: "70%",
                                        transition: "width 2s ease 0s",
                                    }}
                                ></div>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link to={"new"}>
                                        <Button className="me-4 hvr-curl-top-right border-0">
                                            New Booking
                                        </Button>
                                    </Link>
                                </h4>

                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                ></table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

CbList.displayName = "CbList";
export default CbList;
