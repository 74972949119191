import React, { useEffect, useState } from 'react'
import { useMyContext } from '../../../../Context/MyContextProvider';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import CountUp from "react-countup";

const AgentCredit = ({ id }) => {
  const { api, successAlert, authToken } = useMyContext();
  const navigate = useNavigate();

  //wallet states
  const [inputVal, settInputVal] = useState(0);
  const [initialAmount, setInitialAmount] = useState(0);
  const [amount, setAmount] = useState(" ")
  const [newCredit, setNewCredit] = useState(0)
  const [deduction, setDeduction] = useState(false)
  const [userData, setUserData] = useState([])



  const UserCredits = async (id) => {
    if (id) {
      try {
        const response = await axios.get(`${api}chek-user/${id}`,
          {
            headers: {
              'Authorization': 'Bearer ' + authToken,
            }
          });
        setUserData(response.data.balance)
        setInitialAmount(response.data.balance.latest_balance || 0)
        setAmount(response.data.balance.latest_balance || 0)
      } catch {
      }
    }
  }

  useEffect(() => {
    UserCredits(id)
  }, []);

  const handleAmount = (value) => {
    setNewCredit(value);
    settInputVal(value);
    setAmount(amount + value)
    if (value && !isNaN(value)) {
      if (deduction) {
        if (value > initialAmount) {
          setAmount(0)
        } else {
          setAmount(Math.abs(parseFloat(value) - parseFloat(initialAmount)));
        }
      } else {
        setAmount(parseFloat(value) + parseFloat(initialAmount));
      }

    } else {
      setAmount(initialAmount);
    }
  }
  useEffect(() => {
    handleAmount(inputVal)
  }, [deduction])

  useEffect(() => {
    if (amount) {
      const parts = amount?.toString()?.split('.');
      const decimalDigits = parts?.length > 1 ? parts[1]?.slice(0, 2) : '';
      const formattedAmount = parts[0] + (decimalDigits ? '.' + decimalDigits : '');
      setAmount(formattedAmount);
    }
  }, [amount])

  const UpdateBalance = async (e) => {
    if (newCredit) {
      await axios.post(`${api}add-balance`, {
        amount, user_id: userData?.id, newCredit, deduction
      }, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      }).then((response) => {
        if (response.data.status) {
          successAlert('Success', response.data.message)
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  const today = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  return (
    <>
      <Row>
        <Col md="6" className="mb-5 mb-md-0">
          <div className="mb-5">
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <span className="text-dark">Total Balance</span>
              <Link
                className="badge rounded-pill bg-primary-subtle"
                to=""
              >
                View Report
              </Link>
            </div>
            <div className="mb-2">
              <h2 className="counter">
                <CountUp
                  start={0}
                  end={amount}
                  duration={1}
                  useEasing={true}
                  separator=","
                />
              </h2>
              <small>{today()}</small>
            </div>
          </div>
          <div>
          </div>
        </Col>
        <Col md="6">
          <div className="iq-scroller-effect">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-dark">Send Money To</span>
              <span className="badge rounded-pill bg-primary-subtle">
                {userData?.name}
              </span>
            </div>
            <div>
              <div className="form-group custom-choicejs">
                <input
                  type="number"
                  className="form-control"
                  name="ticket quantity"
                  placeholder=""
                  onKeyDown={(event) => {
                    const { key, target } = event;
                    if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
                      return;
                    }
                    if (!(/^\d$/.test(event.key) || event.key === '.')) {
                      event.preventDefault();
                    } else if (target.value.includes('.')) {
                      const decimalIndex = target.value.indexOf('.');
                      const decimalPart = target.value.substring(decimalIndex + 1);
                      if (decimalPart.length >= 2) {
                        event.preventDefault();
                      }
                    } else {
                      return
                    }
                  }}
                  onChange={(e) => handleAmount(parseFloat(e.target.value))}
                />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Form.Check className="form-check d-inline-block pt-1 mb-0">
                    <input type="checkbox" className="form-check-input" id="customCheck11" onChange={(e) => setDeduction(e.target.checked)} />
                    <Form.Label className="form-check-label" htmlFor="customCheck11">Deduction</Form.Label>
                  </Form.Check>
                </div>
              </div>
            </div>
            <Button className="btn btn-primary w-100 mt-2"
              onClick={() => UpdateBalance()}
            >
              Send Money
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AgentCredit
