import React, { useEffect, useState } from "react";
import {Row,Col,Button,Form,Tab,Nav,Card} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";
import AgentCredit from "./AgentCredit";
import BookingList from "../Events/Bookings/BookingList";
import { Shield, ShoppingBag, UserIcon, Wallet } from "lucide-react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../store/slices/authSlice";
const ManageUser = () => {
    const { api, authToken, successAlert, userRole, UserData, UserList,ErrorAlert } = useMyContext();
    const dispatch = useDispatch();
    const { id } = useParams()
    const [bookings, setBookings] = useState([])
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    //user states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [number, setNumber] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [altNumber, setAltNumber] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [bankIfsc, setBankIfsc] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [bankMicr, setBankMicr] = useState('');
    const [validated, setValidated] = useState(false);
    const [roleId, setRoleId] = useState('');
    const [reportingUser, setReportingUser] = useState('');
    const [userType, setUserType] = useState('');
    const [status, setStatus] = useState(false);
    const [roleName, setRoleName] = useState();
    const [disableOrg, setDisableOrg] = useState(false);
    const [disable, setDisable] = useState(false);

    const getBookings = async () => {
        await axios.get(`${api}user-bookings/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setBookings(res.data.bookings)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const UserDetail = async () => {
        if (id) {
            await axios.get(`${api}edit-user/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                if (res.data.status) {
                    const data = res.data?.user;
                    setName(data.name);
                    setEmail(data.email);
                    setPassword(data.password);
                    setNumber(data.phone_number);
                    setOrganisation(data.organisation);
                    setAltNumber(data.alt_number);
                    setPincode(data.pincode);
                    setState(data.state);
                    setCity(data.city);
                    setBankName(data.bank_name);
                    setBankNumber(data.bank_number);
                    setBankIfsc(data.bank_ifsc);
                    setBankBranch(data.bank_branch);
                    setStatus(data.status === 1 ? 'Active' : 'Deative');
                    setBankMicr(data.bank_micr);
                    setReportingUser(UserList.find((item) => item?.value === data.reporting_user_id));
                    setRoleName(data?.role?.name)
                    setRoleId(data?.role?.id)
                    setRoles(res.data?.roles)
                    setUsers(res.data?.allUser)
                    fetchUserRole(data.reporting_user_id)
                }
            }).catch((err) =>
                console.log(err)
            )
        }
    }
    const WalletData = async () => {
        await axios.get(`${api}chek-user/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const fetchUserRole = async (reporting_id) => {
        if (reporting_id) {
            await axios.get(`${api}edit-user/${reporting_id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                if (res.data.status) {
                    const data = res.data?.user[0];
                    if (data?.role?.name === 'Organizer') {
                        setOrganisation(data?.organisation)
                        setDisable(true)
                    } else {
                        setOrganisation('')
                        setDisable(false)

                    }
                }
            }).catch((err) =>
                console.log(err)
            )
        }
    }
    
    useEffect(() => {
        if (userRole === 'Organizer') {
            setReportingUser({ key: UserData?.id, label: UserData?.id })
            setDisableOrg(true)
            setOrganisation(UserData?.organisation)
        }
        getBookings()
        UserDetail()
        WalletData()

    }, []);

    const handleSubmit = async (e) => {
        if(!email){
            ErrorAlert('Please enter email')
            return
        }
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setValidated(true);
            const userData = {
                name,
                email,
                number,
                password,
                organisation,
                alt_number: altNumber,
                pincode,
                state,
                city,
                bank_name: bankName,
                reporting_user: reportingUser?.value,
                bank_number: bankNumber,
                role_id: roleId,
                bank_ifsc: bankIfsc,
                bank_branch: bankBranch,
                bank_micr: bankMicr,
                status: status === 'Active' ? 1 : 0,
            };
            try {
                const response = await axios.post(`${api}update-user/${id}`, userData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if(response.data?.status){
                    if(userRole === 'User'){
                        dispatch(updateUser(response.data.user));
                        window.history.back();
                    }
                    successAlert('User Updated', response.data.message);
                }
            } catch (error) {
                console.error('There was an error creating the user!', error);
            }
        }
    };

    useEffect(() => {
        if (roles?.length > 0 && roleId) {
            const role = roles.find((item) => item.id === Number(roleId));
            setRoleName(role?.name)
        }
    }, [roleId, roles]);

    useEffect(() => {
        if (reportingUser) {
            fetchUserRole(reportingUser?.value)
        }
    }, [reportingUser]);

    const tabs = [
        {
            eventKey: "first",
            id: "profile-tab",
            dataTarget: "#profile",
            ariaControls: "profile",
            ariaSelected: "false",
            Icon: UserIcon,
            label: "Profile",
            condition: true,
        },
        {
            eventKey: "second",
            id: "order-tab",
            dataTarget: "#order",
            ariaControls: "order",
            ariaSelected: "false",
            Icon: ShoppingBag,
            label: "Bookings",
            condition: true,
        },
        {
            eventKey: "third",
            id: "card-tab",
            dataTarget: "#card",
            ariaControls: "card",
            ariaSelected: "true",
            Icon: Wallet,
            label: "Wallet",
            condition: roleName === 'Agent',
        },
    ];

    return (
        <>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Manage User - {roleName}</h4>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="nav nav-tabs nav-iconly gap-5 mb-5 responsive-nav" id="myTab" role="tablist">
                                    {tabs?.map(
                                        (tab, index) =>
                                            tab?.condition && (
                                                <Nav.Link
                                                    key={index}
                                                    as="button"
                                                    eventKey={tab.eventKey}
                                                    className="d-flex flex-column align-items-center w-100"
                                                    id={tab.id}
                                                    data-bs-toggle="tab"
                                                    data-bs-target={tab.dataTarget}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={tab.ariaControls}
                                                    aria-selected={tab.ariaSelected}
                                                >
                                                    <tab.Icon />
                                                    {tab.label}
                                                </Nav.Link>
                                            )
                                    )}
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane
                                        eventKey="first"
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <Form noValidate validated={validated} className='row g-3 needs-validation'>
                                            <Row>
                                                <Col xl={userRole === 'Organizer' ? '12' : '12'} lg="8">
                                                    <div className="header-title d-flex justify-content-between align-items-center w-100">
                                                        <h4 className="card-title">Update {userType ? userType : 'User'} Information</h4>
                                                        <div className="btn">
                                                            <Button onClick={handleSubmit} variant="btn btn-primary">
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="new-user-info">
                                                        <Row>
                                                            <Form.Group className="col-md-4 form-group">
                                                                <Form.Label htmlFor="fname">Name:</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="fname"
                                                                    placeholder="Name"
                                                                    value={name}
                                                                    required
                                                                    onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="col-md-4 form-group">
                                                                <Form.Label htmlFor="email">Email:</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    id="email"
                                                                    required
                                                                    placeholder="Email"
                                                                    autoComplete="new-password"
                                                                    name="new-password-field"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="col-md-4 form-group">
                                                                <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="mobno"
                                                                    placeholder="Mobile Number"
                                                                    value={number}
                                                                    required
                                                                    onChange={(e) => setNumber(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            {userRole !== 'User' &&
                                                                <Row>
                                                                    <Form.Group className="col-md-4 form-group">
                                                                        <Form.Label htmlFor="lname">Organisation:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="lname"
                                                                            required
                                                                            disabled={disableOrg || disable}
                                                                            placeholder="Organisation"
                                                                            value={organisation}
                                                                            onChange={(e) => setOrganisation(e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                    {!disableOrg &&
                                                                        <Form.Group className="col-md-4 form-group">
                                                                            <Form.Label htmlFor="gstvat">Account Manager :</Form.Label>
                                                                            <Select
                                                                                options={UserList}
                                                                                value={reportingUser}
                                                                                className="js-choice"
                                                                                select="one"
                                                                                onChange={(user) => setReportingUser(user)}
                                                                            />
                                                                        </Form.Group>
                                                                    }
                                                                    {!disableOrg &&
                                                                        <>
                                                                            <Form.Group className="col-md-4 form-group">
                                                                                <Form.Label>User Role:</Form.Label>
                                                                                <Form.Select
                                                                                    required
                                                                                    value={roleId}
                                                                                    onChange={(e) => setRoleId(e.target.value)}
                                                                                >
                                                                                    <option value=''>Select</option>
                                                                                    {
                                                                                        roles?.map((item, index) => (
                                                                                            <option value={item?.id} key={index}>{item?.name}</option>
                                                                                        ))
                                                                                    }
                                                                                </Form.Select>
                                                                                <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        </>
                                                                    }
                                                                    {!userType &&
                                                                        <>
                                                                            <hr />
                                                                            <h5 className="mb-3">Banking</h5>
                                                                            <Form.Group className="col-md-3 form-group">
                                                                                <Form.Label htmlFor="add1">Bank Name:</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    id="add1"

                                                                                    placeholder="Bank Name"
                                                                                    value={bankName}
                                                                                    onChange={(e) => setBankName(e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className="col-md-3 form-group">
                                                                                <Form.Label htmlFor="add2">Bank IFSC Code:</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    id="add2"

                                                                                    placeholder="Bank IFSC Code"
                                                                                    value={bankIfsc}
                                                                                    onChange={(e) => setBankIfsc(e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className="col-md-3 form-group">
                                                                                <Form.Label htmlFor="cname">Branch Name:</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    id="cname"

                                                                                    placeholder="Branch Name"
                                                                                    value={bankBranch}
                                                                                    onChange={(e) => setBankBranch(e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className="col-md-3 form-group">
                                                                                <Form.Label htmlFor="cname">Account Number:</Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    id="cname"

                                                                                    placeholder="Account Number"
                                                                                    value={bankNumber}
                                                                                    onChange={(e) => setBankNumber(e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                        </>
                                                                    }
                                                                    <hr />
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <h5 className="mb-3">Address</h5>
                                                                                <div className="row">
                                                                                    <Form.Group className="col-md-6 form-group">
                                                                                        <Form.Label htmlFor="city">Town/City:</Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            id="city"

                                                                                            placeholder="Town/City"
                                                                                            value={city}
                                                                                            onChange={(e) => setCity(e.target.value)}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    <Form.Group className="col-md-6 form-group">
                                                                                        <Form.Label htmlFor="pno">Pin Code:</Form.Label>
                                                                                        <Form.Control
                                                                                            type="number"
                                                                                            id="pno"
                                                                                            placeholder="Pin Code"
                                                                                            value={pincode}
                                                                                            onChange={(e) => setPincode(e.target.value)}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                            {userType === '' &&
                                                                                <div className="col-md-6">
                                                                                    <h5 className="mb-3">Other</h5>
                                                                                    <div className="row">
                                                                                        <Form.Group className="col-md-6 form-group">
                                                                                            <Form.Label htmlFor="gstvat">GST / VAT Tax:</Form.Label>
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                id="gstvat"
                                                                                                placeholder="GST / VAT Tax"
                                                                                                onChange={(e) => (e.target.value)}
                                                                                            />
                                                                                        </Form.Group>
                                                                                        <Form.Group className="col-md-6 form-group">
                                                                                            <Form.Label>User Status:</Form.Label>
                                                                                            <Form.Select
                                                                                                required
                                                                                                value={status}
                                                                                                onChange={(e) => setStatus(e.target.value)}
                                                                                            >
                                                                                                <option value='Active'>Active</option>
                                                                                                <option value='Deative'>Deactive</option>
                                                                                            </Form.Select>
                                                                                            <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            }
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Card className="border border-dashed border-2 shadow-none mb-0 rounded border-primary">
                                            <div className="card-header">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 className="mb-0">Secure Your Account</h4>
                                                    <Button className="btn btn-primary">Enable</Button>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Shield className="text-success" />
                                                    </div>
                                                    <p className="ms-3 mb-0">
                                                        Two-factor authentication adds an extra layer of
                                                        security to your account. To log in, in addition
                                                        you'll need to provide a 6 digit code
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="second"
                                        id="order"
                                        role="tabpanel"
                                        aria-labelledby="order-tab"
                                    >
                                        <BookingList bookings={bookings} />
                                        <div className="col-12 text-center">
                                            <Link to="#" className="btn btn-primary">
                                                Load More
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="third"
                                        id="card"
                                        role="tabpanel"
                                        aria-labelledby="card-tab"
                                    >
                                        <h4 className="mb-4">Your Payment Options</h4>
                                        <AgentCredit id={id} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ManageUser
