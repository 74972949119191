import { EditIcon } from 'lucide-react'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { DleteIcon } from '../../../CustomHooks/CustomIcon'


const BookingsAttendee = ({ attendeeList, apiData, handleOpenModal, handleDeleteAttendee, ShowAction }) => {
    return (
        <>
            {attendeeList?.map((attendee, index) => (
                <div key={index} className={`custom-dotted-border m-2 rounded-3 ${attendee?.missingFields?.length > 0 ? 'border-danger' : ''}`}>
                    <Card.Header className="d-flex justify-content-between">
                        Attendee Details - Ticket {index + 1}
                        {ShowAction &&
                            <div className="d-flex gap-2">
                                <div className="cursor-pointer" onClick={() => handleOpenModal(index)}>
                                    <EditIcon size={20} />
                                </div>
                                <div className="cursor-pointer" onClick={() => handleDeleteAttendee(index)}>
                                    <DleteIcon size={25} />
                                </div>
                            </div>
                        }
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {apiData?.map((field, fieldIndex) => (
                                <Col xs={6} md={4} key={fieldIndex} className="text-black mb-2">
                                    <strong>{field?.field_name}: </strong>
                                    {attendee[field?.field_name] instanceof File
                                        ? <img
                                            src={URL.createObjectURL(attendee[field?.field_name])}
                                            alt={`${field?.field_name} preview`}
                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }}
                                        />
                                        : typeof attendee[field?.field_name] === 'string' && (
                                            (attendee[field?.field_name].startsWith("http://") || attendee[field?.field_name].startsWith("https://")) &&
                                            /\.(jpg|jpeg|png|gif|bmp|webp|ico)$/i.test(attendee[field?.field_name])
                                        )
                                            ? <img
                                                style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }}
                                                src={attendee[field?.field_name]}
                                                alt={`${field?.field_name} preview`}
                                            />
                                            : typeof attendee[field?.field_name] !== 'object'
                                                ? attendee[field?.field_name] !== null && attendee[field?.field_name]
                                                : <span className="text-muted">[File]</span>
                                    }
                                </Col>

                            ))}
                        </Row>
                        {ShowAction && attendee?.missingFields?.length > 0 && (
                            <div className="text-danger">
                                <p><strong>Missing Fields:</strong> {attendee.missingFields.join(', ')}</p>
                            </div>
                        )}
                    </Card.Body>
                </div>
            ))}
        </>
    )
}

export default BookingsAttendee